import tailwindConfig from "tailwind.config"

type Sizes = keyof typeof tailwindConfig.theme.screens

type Props = Partial<Record<Sizes, string>>

export function generateImageSizesProp(initial: string, sizes?: Props): string {
  let sizesString = initial

  if (!sizes) {
    return sizesString
  }

  Object.keys(sizes).map((size) => {
    if (!sizes[size as Sizes]) {
      return
    }

    sizesString = `(min-width: ${tailwindConfig.theme.screens?.[size]}) ${
      sizes[size as Sizes]
    }, ${sizesString}`
  })

  return sizesString
}
