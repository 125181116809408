import Image from "next/image"
import type { ACFImage } from "@/types/wordpress"
import { generateImageSizesProp } from "utils/generateImageSizesProp"

type Props = {
  image: ACFImage
}

export default function ImageBackground({ image }: Props) {
  return (
    <figure className="relative z-10 h-[220px] sm:h-[374px] md:h-[450px] lg:h-full w-full">
      <Image
        className="object-cover"
        src={image.sourceUrl}
        alt={image.altText}
        layout="fill"
        sizes={generateImageSizesProp("100vw", {
          lg: "50vw"
        })}
      />
    </figure>
  )
}
