import ACFLink from "@/components/ACFLink"
import { SFBFShape } from "@/components/icons/sfbfShapes"
import Wysiwyg from "@/components/wysiwyg"
import { Container } from "@/styledComponents/content"
import type { ACFImage, TACFLink, TVideo } from "@/types/wordpress"
import ImageBackground from "./layouts/image"
import cx from "classnames"

export type THero = TVideo & {
  fieldGroupName: "Page_Pagecomponentsgroup_PageComponents_Hero"
  heading: string
  wysiwyg: string
  startpage: boolean
  image: ACFImage | null
  button: TACFLink
  buttonSecondary: TACFLink
  visibility?: string
}

export default function Hero({
  heading,
  wysiwyg,
  startpage,
  image,
  button,
  buttonSecondary
}: THero) {
  const isStartpage = startpage === true

  return (
    <section
      className={cx(
        "lg:flex lg:justify-end relative overflow-hidden bg-blue-dark",
        {
          "lg:h-[512px] xl:h-[650px]": isStartpage,
          "lg:min-h-[364px] xl:min-h-[440px]": !isStartpage
        }
      )}>
      {isStartpage && image && (
        <div className={"lg:w-1/2 lg:flex-shrink-0 z-10 lg:h-full"}>
          <ImageBackground image={image} />
        </div>
      )}
      <Container
        className={cx("lg:inset-0 flex items-center py-12 lg:py-0", {
          "lg:absolute lg:mx-auto lg:left-0 lg:right-0": isStartpage
        })}>
        <div
          className={cx("lg:w-1/2", {
            "lg:pr-5 xl:pr-36 relative": isStartpage,
            "py-14": !isStartpage
          })}>
          <div className="relative z-10 w-full text-white">
            <h1 className="mb-3 xl:mb-6">{heading}</h1>
            <Wysiwyg wysiwyg={wysiwyg} />
          </div>

          {button || buttonSecondary ? (
            <div className="relative z-10 flex flex-col w-full mt-5 xl:mt-8 sm:flex-row">
              {button && <ACFLink link={button} />}
              {buttonSecondary && (
                <ACFLink
                  styleType="blue"
                  link={buttonSecondary}
                  className="!ml-0 sm:!ml-8 mt-4 sm:mt-0"
                />
              )}
            </div>
          ) : null}
          <SFBFShape
            color={"blue"}
            className={cx(
              "absolute z-0 -right-[250px] sm:-right-[300px] md:-right-[350px] w-[400px] sm:w-[450px] md:w-[500px] lg:w-[600px] xl:w-[800px]",
              {
                "-bottom-[250px] md:-bottom-[300px] lg:-bottom-[400px] xl:-bottom-[550px] lg:-right-[250px]":
                  !isStartpage,
                "-bottom-[320px] md:-bottom-[400px] lg:-bottom-[500px] xl:-bottom-[650px] lg:-right-[400px] xl:-right-[500px]":
                  isStartpage
              }
            )}
          />
        </div>
        {isStartpage && <div className="hidden w-1/2 lg:block"></div>}
      </Container>
    </section>
  )
}

export const QUERY = `#graphql
  ... on Page_Pagecomponentsgroup_PageComponents_Hero {
    fieldGroupName
    heading
    wysiwyg
    startpage
    visibility
    button {
      target
			title
      url
    }

    buttonSecondary {
      target
			title
      url
    }

    image {
      altText
      sourceUrl
      mediaDetails {
        height
        width
      }
    }
  }
`
