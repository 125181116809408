import { ButtonLink, ButtonLinkExternal, ButtonProps } from "./buttons"
import type { TACFLink } from "@/types/wordpress"

type Props = Omit<ButtonProps, "children"> & {
  link?: TACFLink
}

export function ACFLink({ link, ...rest }: Props) {
  if (!link) {
    return null
  }

  const { url, title, target } = link

  if (!url || !title) {
    return null
  }

  if (target) {
    return (
      <ButtonLinkExternal href={url} {...rest}>
        {title}
      </ButtonLinkExternal>
    )
  } else {
    return (
      <ButtonLink href={url} {...rest}>
        {title}
      </ButtonLink>
    )
  }
}

export default ACFLink
