import type { TIconProps } from "./types"
type TProps = TIconProps & {
  color: "blue" | "white"
}
export function SFBFShape({ className, color }: TProps) {
  return (
    <svg
      className={className}
      preserveAspectRatio="xMinYMin meet"
      viewBox="0 0 800 1189"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M432.721 1186.39L91.9194 593.141L432.721 1.99754L775.626 593.141L432.721 1186.39Z"
        stroke={color === "blue" ? "#14477C" : "#fff"}
        strokeWidth="2"
      />
      <path
        d="M308.179 1127.28L1.154 593.141L308.179 61.1042L615.205 593.141L308.179 1127.28Z"
        stroke={color === "blue" ? "#14477C" : "#fff"}
        strokeWidth="2"
      />
      <path
        d="M455.937 1066.08L182.686 593.141L455.937 120.206L729.189 593.141L455.937 1066.08Z"
        stroke={color === "blue" ? "#14477C" : "#fff"}
        strokeWidth="2"
      />
      <path
        d="M455.936 1006.97L216.458 593.139L455.936 179.305L695.414 593.139L455.936 1006.97Z"
        stroke={color === "blue" ? "#14477C" : "#fff"}
        strokeWidth="2"
      />
      <path
        d="M593.144 947.866L389.544 593.141L593.144 240.514L798.843 593.141L593.144 947.866Z"
        stroke={color === "blue" ? "#14477C" : "#fff"}
        strokeWidth="2"
      />
    </svg>
  )
}
