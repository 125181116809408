import { Content } from "@/components/styled/content"
import parse from "html-react-parser"

type Props = {
  wysiwyg: string | null
}

export default function Wysiwyg({ wysiwyg }: Props) {
  if (!wysiwyg) {
    return null
  }

  return <Content className="flex-grow">{parse(wysiwyg)}</Content>
}
